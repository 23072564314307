import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const findItemByKey = (items, keyName, key) => {
  for (let i = 0, iMax = items.length; i < iMax; i++) {
    if (items[i][keyName].toString() === key.toString()) {
      return i;
    }
  }
  return null;
};
const updateItemByIndex = (state, type, index, newData) => {
  if (typeof state[type][index] === 'undefined') {
    throw new Error('Song not found');
  }
  Vue.set(state[type], index, newData);
};

export default new Vuex.Store({
  state: {
    requestsCount: 0,
    appConfig: {},
    news: [],
    currentNews: null,
    catalog: [],
    favourites: [],
    catalogSearch: null,
    favouritesSearch: null,
  },
  getters: {
    requestsCount: (state) => state.requestsCount,
    appConfig: (state) => (key) => state.appConfig[key] || null,
    catalog: (state) => state.catalog,
    favourites: (state) => state.favourites,
    catalogSearch: (state) => state.catalogSearch,
    favouritesSearch: (state) => state.favouritesSearch,
    news: (state) => state.news,
    currentNews: (state) => state.currentNews,
  },
  mutations: {
    setCurrentNews(state, value) {
      state.currentNews = value;
    },
    setCatalogSearch(state, value) {
      state.catalogSearch = value;
    },
    setFavouritesSearch(state, value) {
      state.favouritesSearch = value;
    },
    upRequests(state) {
      state.requestsCount += 1;
    },
    downRequests(state) {
      if (state.requestsCount > 0) {
        state.requestsCount -= 1;
      }
    },
    setAppConfig(state, config) {
      const json = JSON.parse(atob(config));
      Object.keys(json).forEach((key) => {
        state.appConfig[key] = json[key];
      });
    },
    setItems(state, { type, items }) {
      state[type] = items;
    },
    addItem(state, { type, item }) {
      state[type].push(item);
    },
    updateItemByIndex(state, { type, index, newData }) {
      updateItemByIndex(state, type, index, newData);
    },
    updateItemByKey(state, {
      type,
      keyName,
      key,
      newData,
    }) {
      const index = findItemByKey(state[type], keyName, key);
      updateItemByIndex(state, type, index, newData);
    },
    deleteItemByKey(state, { type, keyName, key }) {
      const index = findItemByKey(state[type], keyName, key);
      if (index !== null) {
        Vue.delete(state[type], index);
      }
    },
  },
  actions: {
  },
  modules: {
  },
});
