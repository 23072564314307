<template>
  <div v-if="letters.length">
    <a
      href="#"
      title="Очистить выбор первой буквы"
      :class="{disabled: !current}"
      class="clear-letter"
      @click.prevent="clearLetter">
      <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <linearGradient id="paint0_linear_3139_260" gradientUnits="userSpaceOnUse" x1="10" x2="10" y1="0" y2="20">
          <stop offset="0" stop-color="#ee9ae5"/>
          <stop offset="1" stop-color="#5961f9"/>
        </linearGradient>
        <path clip-rule="evenodd" d="m0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm7.70711-3.70711c-.39053-.39052-1.02369-.39052-1.41422 0-.39052.39053-.39052 1.02369 0 1.41422l2.2929 2.29289-2.2929 2.2929c-.39052.3905-.39052 1.0237 0 1.4142.39053.3905 1.02369.3905 1.41422 0l2.29289-2.2929 2.2929 2.2929c.3905.3905 1.0237.3905 1.4142 0s.3905-1.0237 0-1.4142l-2.2929-2.2929 2.2929-2.29289c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0l-2.2929 2.2929z" fill="url(#paint0_linear_3139_260)" fill-rule="evenodd"/>
      </svg>
    </a>
    <ul class="catalog-letters">
      <li v-for="letter in letters"  :key="`${type}-${letter}`">
        <a
          href="#"
          :class="{active: current === letter}"
          @click.prevent="onLetterClick(letter)">{{letter}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    letters: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    current: {
      type: String,
      default: null,
    },
  },
  methods: {
    onLetterClick(letter) {
      this.$emit('letter-selected', letter);
    },
    clearLetter() {
      this.$emit('clear-letter');
    },
  },
};
</script>
