<template>
  <div class="vfl-has-label">
    <label class="vfl-label" :class="classObject">
      {{ label }}
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    classObject() {
      if (this.show) {
        return 'vfl-label-on-input';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.vfl-has-label {
  position: relative;
}

.vfl-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0.1em;
  overflow: hidden;
  font-family: sans-serif;
  font-size: 0.8em;
  color: #aaa;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.vfl-has-label input:focus,
.vfl-has-label textarea:focus,
.vfl-has-label select:focus {
  outline: 0;
}

.vfl-label-on-input {
  top: -1.3em;
  pointer-events: all;
  opacity: 1;
}
</style>
