export default {
  data() {
    return {
      endpoint: '',
      state: {},
      validators: {},
      errors: [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  methods: {
    validateFromResponse(response) {
      if (typeof response.data.validate !== 'undefined') {
        const keys = Object.keys(response.data.validate);
        for (let i = 0, iMax = keys.length; i < iMax; i++) {
          this.addError(keys[i], response.data.validate[keys[i]].join(', '));
        }
        return false;
      }
      return true;
    },
    addError(field, error) {
      this.$set(this.errors, field, error);
    },
    clearErrors(field) {
      this.$delete(this.errors, field);
    },
    clearAllErrors() {
      this.$set(this, 'errors', []);
    },
    getErrors(field) {
      if (typeof this.errors[field] !== 'undefined') {
        return this.errors[field];
      }
      return '';
    },
  },
};
