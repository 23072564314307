<template>
  <transition name="fade">
    <div class="form-group__error" v-if="hasError">{{error}}</div>
  </transition>
</template>

<script>
export default {
  name: 'FieldError',
  props: ['error'],
  computed: {
    hasError() {
      return typeof this.error === 'string' && this.error.length > 0;
    },
  },
};
</script>
