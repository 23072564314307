<template>
  <div class="content">
    <div class="content__logo">
      <img src="../assets/img/Logo.png" alt="logo">
    </div>
    <div class="content__info">
      <h1 class="content__title">{{title}}</h1>
      <div class="content__descr" v-html="text"></div>
<!--      <button class="content__btn">Личный кабинет</button>-->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$store.getters.appConfig('main_page_title');
    },
    text() {
      return this.$store.getters.appConfig('main_page_text');
    },
  },
};
</script>
