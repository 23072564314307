<template>
  <div class="less-more-input">
    <div class="less-more-input__less">
      <a href="#" @click.prevent="less">
        <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-outside-1_122_9702" maskUnits="userSpaceOnUse" x="-0.991856" y="-1.56925" width="21.2132" height="21.2132" fill="black">
            <rect fill="white" x="-0.991856" y="-1.56925" width="21.2132" height="21.2132"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75326 17.3683C9.22905 17.844 10.0004 17.844 10.4762 17.3683C10.952 16.8925 10.952 16.1211 10.4762 15.6453L4.44582 9.61488L10.4762 3.58448C10.952 3.1087 10.952 2.3373 10.4762 1.86151C10.0004 1.38573 9.22903 1.38573 8.75325 1.86151L1.86137 8.75339C1.38558 9.22918 1.38558 10.0006 1.86137 10.4764C1.86137 10.4764 1.86138 10.4764 1.86138 10.4764L8.75326 17.3683Z"/>
          </mask>
          <path d="M8.75326 17.3683L9.46037 16.6611L8.75326 17.3683ZM4.44582 9.61488L3.73872 8.90777L3.03161 9.61488L3.73872 10.322L4.44582 9.61488ZM10.4762 3.58448L11.1833 4.29159L10.4762 3.58448ZM8.75325 1.86151L9.46035 2.56862L8.75325 1.86151ZM1.86137 8.75339L1.15426 8.04628L1.15426 8.04628L1.86137 8.75339ZM1.86138 10.4764L1.15427 11.1835L1.86138 10.4764ZM9.76912 16.6611C9.68386 16.7464 9.54563 16.7464 9.46037 16.6611L8.04615 18.0754C8.91246 18.9417 10.317 18.9417 11.1833 18.0754L9.76912 16.6611ZM9.76912 16.3524C9.85438 16.4376 9.85438 16.5759 9.76912 16.6611L11.1833 18.0754C12.0496 17.209 12.0496 15.8045 11.1833 14.9382L9.76912 16.3524ZM3.73872 10.322L9.76912 16.3524L11.1833 14.9382L5.15293 8.90777L3.73872 10.322ZM9.76911 2.87737L3.73872 8.90777L5.15293 10.322L11.1833 4.29159L9.76911 2.87737ZM9.76911 2.56862C9.85437 2.65388 9.85437 2.79211 9.76911 2.87737L11.1833 4.29159C12.0496 3.42528 12.0496 2.02071 11.1833 1.1544L9.76911 2.56862ZM9.46035 2.56862C9.54561 2.48336 9.68385 2.48336 9.76911 2.56862L11.1833 1.1544C10.317 0.288096 8.91245 0.288096 8.04614 1.15441L9.46035 2.56862ZM2.56848 9.4605L9.46035 2.56862L8.04614 1.15441L1.15426 8.04628L2.56848 9.4605ZM2.56848 9.76925C2.48321 9.68399 2.48322 9.54576 2.56848 9.4605L1.15426 8.04628C0.287952 8.91259 0.287953 10.3172 1.15426 11.1835L2.56848 9.76925ZM2.56849 9.76927C2.56849 9.76926 2.56848 9.76926 2.56848 9.76926C2.56848 9.76926 2.56848 9.76926 2.56848 9.76925L1.15426 11.1835C1.15426 11.1835 1.15427 11.1835 1.15427 11.1835C1.15427 11.1835 1.15427 11.1835 1.15427 11.1835L2.56849 9.76927ZM9.46037 16.6611L2.56849 9.76927L1.15427 11.1835L8.04615 18.0754L9.46037 16.6611Z" fill="black" mask="url(#path-1-outside-1_122_9702)"/>
        </svg>
      </a>
    </div>
    <div class="less-more-input__value">
      {{value}}
    </div>
    <div class="less-more-input__more">
      <a href="#" @click.prevent="more">
        <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-outside-1_122_9701" maskUnits="userSpaceOnUse" x="-7.76236" y="-0.414214" width="21.2132" height="21.2132" fill="black">
            <rect fill="white" x="-7.76236" y="-0.414214" width="21.2132" height="21.2132"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70572 1.86148C3.22994 1.3857 2.45854 1.3857 1.98275 1.86148C1.50697 2.33727 1.50697 3.10867 1.98275 3.58445L8.01316 9.61486L1.98277 15.6453C1.50698 16.121 1.50698 16.8924 1.98277 17.3682C2.45855 17.844 3.22995 17.844 3.70574 17.3682L10.5976 10.4763C11.0734 10.0006 11.0734 9.22916 10.5976 8.75338C10.5976 8.75337 10.5976 8.75337 10.5976 8.75336L3.70572 1.86148Z"/>
          </mask>
          <path d="M1.98275 3.58445L1.27565 4.29156L1.98275 3.58445ZM8.01316 9.61486L8.72027 10.322L9.42737 9.61486L8.72027 8.90775L8.01316 9.61486ZM1.98277 15.6453L1.27566 14.9381L1.98277 15.6453ZM3.70574 17.3682L2.99863 16.6611L3.70574 17.3682ZM10.5976 10.4763L11.3047 11.1835L11.3047 11.1835L10.5976 10.4763ZM2.68986 2.56859C2.77512 2.48333 2.91336 2.48333 2.99862 2.56859L4.41283 1.15438C3.54652 0.288068 2.14196 0.288069 1.27565 1.15438L2.68986 2.56859ZM2.68986 2.87735C2.6046 2.79209 2.6046 2.65385 2.68986 2.56859L1.27565 1.15438C0.409337 2.02069 0.409338 3.42525 1.27565 4.29156L2.68986 2.87735ZM8.72027 8.90775L2.68986 2.87735L1.27565 4.29156L7.30605 10.322L8.72027 8.90775ZM2.68987 16.3524L8.72027 10.322L7.30605 8.90775L1.27566 14.9381L2.68987 16.3524ZM2.68987 16.6611C2.60461 16.5759 2.60461 16.4376 2.68987 16.3524L1.27566 14.9381C0.409349 15.8045 0.40935 17.209 1.27566 18.0753L2.68987 16.6611ZM2.99863 16.6611C2.91337 16.7464 2.77513 16.7464 2.68987 16.6611L1.27566 18.0753C2.14197 18.9416 3.54653 18.9416 4.41284 18.0753L2.99863 16.6611ZM9.89051 9.76924L2.99863 16.6611L4.41284 18.0753L11.3047 11.1835L9.89051 9.76924ZM9.89051 9.46048C9.97577 9.54574 9.97577 9.68398 9.89051 9.76924L11.3047 11.1835C12.171 10.3171 12.171 8.91258 11.3047 8.04627L9.89051 9.46048ZM9.8905 9.46047C9.8905 9.46047 9.8905 9.46047 9.8905 9.46048C9.8905 9.46048 9.89051 9.46048 9.89051 9.46048L11.3047 8.04627C11.3047 8.04627 11.3047 8.04627 11.3047 8.04626C11.3047 8.04626 11.3047 8.04626 11.3047 8.04626L9.8905 9.46047ZM2.99862 2.56859L9.8905 9.46047L11.3047 8.04626L4.41283 1.15438L2.99862 2.56859Z" fill="black" mask="url(#path-1-outside-1_122_9701)"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: Number,
    max: Number,
  },
  methods: {
    less() {
      const value = this.value - 1;
      if (!this.min || this.min <= value) {
        this.$emit('input', value);
      }
    },
    more() {
      const value = this.value + 1;
      if (!this.max || this.max >= value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss">
  .less-more-input {
    display: flex;
    justify-content: center;
    &__value {
      border: 1px solid #000000;
      border-radius: 20px;
      margin: 0 4px;
      min-width: 80px;
    }
  }
</style>
