<template>
  <div>
    <h1 class="main-title">Забронировать</h1>
    <div class="booking">
      <form class="booking__form" @submit.prevent="onSubmit">
        <div class="form-group">
          <float-label label="Телефон" :show="!!form.phone">
            <input
              class="booking__input"
              type="text"
              v-mask="'+7 (###) ###-##-##'"
              v-model="form.phone"
              required="required"
              placeholder="+7 (999) 999-99-99"
              @input="clearErrors('phone')">
          </float-label>
          <field-error :error="getErrors('phone')"></field-error>
        </div>
        <div class="form-group">
          <float-label label="Имя" :show="!!form.name">
            <input
              class="booking__input"
              type="text"
              v-model="form.name"
              placeholder="Ваше имя..."
              @input="clearErrors('name')">
          </float-label>
          <field-error :error="getErrors('name')"></field-error>
        </div>
        <div class="form-group">
          <float-label label="Дата" :show="!!form.date">
            <datepicker
              monday-first
              v-bind="datepickerConfig"
              v-model="form.date"
              required @input="clearErrors('date')"></datepicker>
          </float-label>
          <field-error :error="getErrors('date')"></field-error>
        </div>
        <div class="form-group">
          <float-label label="Время" :show="!!form.time">
            <vue-timepicker
              close-on-complete
              :minute-interval="10"
              hour-label="Часы"
              minute-label="Минуты"
              format="HH час. mm мин."
              v-model="form.time"
              placeholder="Выберите время"
              @input="clearErrors('time')"
            ></vue-timepicker>
          </float-label>
          <field-error :error="getErrors('time')"></field-error>
        </div>
        <div class="form-group">
          <float-label label="Количество человек" :show="!!form.quantity">
            <input
              class="booking__input"
              type="number"
              min="0"
              step="1"
              max="100"
              v-model="form.quantity"
              placeholder="Количество человек"
              @input="clearErrors('quantity')">
          </float-label>
          <field-error :error="getErrors('quantity')"></field-error>
        </div>
        <button class="booking__btn" type="submit">Забронировать</button>
      </form>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ru } from 'vuejs-datepicker/dist/locale';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import FloatLabel from '@/components/FloatingLabel.vue';
import formMixin from '@/components/formMixin';
import FieldError from '@/components/FieldError.vue';

const formDefault = {
  date: new Date(),
  quantity: null,
  name: null,
  phone: null,
  time: null,
};

export default {
  components: {
    Datepicker,
    FieldError,
    VueTimepicker,
    FloatLabel,
  },
  mixins: [formMixin],
  data() {
    const nowDate = new Date();
    const yesterdayDay = nowDate.getDate() - 1;
    nowDate.setDate(yesterdayDay);
    return {
      form: JSON.parse(JSON.stringify(formDefault)),
      dpLocale: ru,
      datepickerConfig: {
        language: ru,
        format: 'dd.MM.yyyy',
        placeholder: 'Выберите дату',
        openDate: new Date(),
        disabledDates: {
          to: nowDate,
        },
      },
    };
  },
  methods: {
    clear() {
      this.form = JSON.parse(JSON.stringify(formDefault));
      this.setUser();
    },
    onSubmit(e) {
      e.preventDefault();
      const formData = JSON.parse(JSON.stringify(this.form));
      const dateObject = new Date(this.form.date);
      const day = `0${dateObject.getDate()}`.slice(-2);
      const month = `0${dateObject.getMonth() + 1}`.slice(-2);
      formData.date = `${day}.${month}.${dateObject.getFullYear()}`;
      this.$http.post('/book', formData).then((response) => {
        if (this.validateFromResponse(response)) {
          this.clear();
        }
      });
    },
    setUser() {
      if (this.$auth.check()) {
        this.$set(this.form, 'name', this.$auth.user().firstName);
        this.$set(this.form, 'phone', this.$auth.user().phone);
      }
    },
  },
  created() {
    this.setUser();
  },
};
</script>
