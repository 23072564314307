<template>
  <div>
    <div class="container">
      <main-menu></main-menu>
      <div class="catalog">
        <router-view/>
      </div>
    </div>
    <async-loader></async-loader>
  </div>
</template>
<script>
import AsyncLoader from '@/components/AsyncLoader.vue';
import MainMenu from '@/components/menu/MainMenu.vue';

export default {
  components: { MainMenu, AsyncLoader },
  props: ['config'],
  created() {
    this.$store.commit('setAppConfig', this.config);
  },
};
</script>
