<template>
  <div>
    <h1 class="content__title">Новости</h1>
    <div class="news">
      <div class="news__catalog">
        <div class="news__catalog-oll" v-if="needViewList">
          <div class="news__wrapper">
            <infinite-scroll
              payload-url="/news"
              class="custom-scroll"
              type="news"
              @onLoad="onDataLoad"
              v-slot="{item}">
              <div class="news__oll" @click.prevent="setCurrentNews(item)">
                <div class="news__img" :style="`background-image: url('${item.image}')`"></div>
                <div class="news__titl">
                  <h2>{{item.title}}</h2>
                </div>
                <div class="news__data">{{item.date}}</div>
              </div>
            </infinite-scroll>
          </div>
        </div>
        <div class="news__catalog-favourites news__catalog-favourites_active custom-scroll" v-if="currentNews">
          <div class="news__favorites">
            <div class="news__favourites__back" v-if="isMobile">
              <a href="#" @click.prevent="setCurrentNews(null)">&lt; Назад</a>
            </div>
            <div class="news__img-lg">
              <img :src="currentNews.image" alt="image">
            </div>
            <div class="news__data-lg"><p>{{currentNews.date}}</p></div>
            <div class="news__titl-lg"><h2>{{currentNews.title}}</h2></div>
            <div class="news__text" v-html="currentNews.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteScroll from '@/components/InfiniteScroll.vue';

export default {
  components: { InfiniteScroll },
  data() {
    return {
      windowWidth: window.innerWidth,
      allNews: null,
    };
  },
  computed: {
    currentNews() {
      return this.$store.getters.currentNews;
    },
    needViewList() {
      if (!this.isMobile) {
        return true;
      }
      return !this.currentNews;
    },
    isMobile() {
      return this.windowWidth <= 1200;
    },
  },
  methods: {
    onDataLoad(event) {
      this.$set(this, 'allNews', event);
    },
    setCurrentNews(news) {
      this.$store.commit('setCurrentNews', news);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  watch: {
    allNews(v) {
      if (v && v.length && !this.currentNews && !this.isMobile) {
        this.setCurrentNews(v[0]);
      }
    },
  },

  beforeDestroy() {
    if (this.isMobile) {
      this.setCurrentNews(null);
    }
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
