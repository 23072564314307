<template>
  <component
    :is="componentName"
    v-bind="$attrs"
    class="infinite-scroll"
    v-infinite-scroll="getData"
    infinite-scroll-distance="100">
    <template v-for="(item, index) in responseData">
      <slot :item="item" :index="index"></slot>
    </template>
  </component>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';

export default {
  directives: { infiniteScroll },
  props: {
    component: String,
    // URL с которого будут браться данные
    payloadUrl: {
      type: String,
      required: true,
    },
    // Дополнительные GET параметры запроса
    queryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: true,
    },
    itemsIndex: String,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: null,
      totalItems: null,
      isUpdate: false,
    };
  },
  computed: {
    responseData() {
      return this.$store.getters[this.type];
    },
    componentName() {
      if (this.component) {
        return this.component;
      }
      return 'div';
    },
    nextPage() {
      if (this.currentPage >= this.totalPages) {
        return this.totalPages;
      }
      if (this.currentPage < 1) {
        return 1;
      }
      return this.currentPage + 1;
    },
    allQueryParams() {
      return {
        ...this.queryParams,
        page: this.nextPage,
      };
    },
  },
  methods: {
    updateByKey(keyName, key, newData) {
      this.$store.commit('updateItemByKey', {
        type: this.type,
        keyName,
        key,
        newData,
      });
    },
    updateItemByIndex(index, newData) {
      this.$store.commit('updateItemByIndex', { type: this.type, index, newData });
    },
    deleteItemByKey(keyName, key) {
      this.$store.commit('deleteItemByKey', { type: this.type, keyName, key });
    },
    clearSearch() {
      this.currentPage = 0;
    },
    addItem(item) {
      this.$store.commit('addItem', { item, type: this.type });
    },
    getData(isSearch = false) {
      if (this.totalPages > 0 && this.currentPage > 0 && this.totalPages === this.currentPage && !isSearch) {
        return;
      }
      const params = JSON.parse(JSON.stringify(this.allQueryParams));
      if (isSearch) {
        params.page = 1;
      }
      this.$http.request({
        method: 'get',
        url: this.payloadUrl,
        params,
      }).then((response) => {
        if (response.data) {
          this.$emit('data-loaded', response.data);
          const data = this.itemsIndex ? response.data[this.itemsIndex] || [] : response.data;
          if (isSearch) {
            this.$store.commit('setItems', { type: this.type, items: data });
          } else {
            data.forEach((item) => {
              this.$store.commit('addItem', { item, type: this.type });
            });
          }
          this.$nextTick(() => {
            this.$emit('onLoad', response.data);
          });
        }
        this.setFromHeaders(response.headers, 'x-pagination-page-count', 'totalPages');
        this.setFromHeaders(response.headers, 'x-pagination-current-page', 'currentPage');
        this.setFromHeaders(response.headers, 'x-pagination-total-count', 'totalItems');
      });
    },
    setFromHeaders(headers, header, param) {
      if (typeof headers[header] !== 'undefined') {
        this.$set(this, param, parseInt(headers[header], 10));
      }
    },
  },
};
</script>
