<template>
  <div class="karaoke-catalog">
    <h1 class="catalog__title">Караоке</h1>
    <div class="karaoke-catalog__header">
      <div class="karaoke-catalog__header__all">
        <div class="catalog__heder header-all" :class="{active: catalogOpened}">
          <template v-if="isMobile">
            <a href="#" @click.prevent="selectTab('all')"><h2 class="catalog__name">Все</h2></a>
          </template>
          <template v-else>
            <h2 class="catalog__name">Все</h2>
          </template>
          <div class="catalog__search" v-show="catalogOpened">
            <span class="catalog__icon"><img src="@/assets/img/Search.png" alt=""></span>
            <input
              @input="searchCatalog"
              class="catalog__search-form"
              type="search"
              :value="catalogSearch"
              placeholder="Поиск..." />
          </div>
        </div>
      </div>
      <div class="karaoke-catalog__header__favourites">
        <div class="catalog__heder" :class="{active: favouritesOpened}">
          <template v-if="isMobile">
            <a href="#" @click.prevent="selectTab('favourites')"><h2 class="catalog__name">Избранное</h2></a>
          </template>
          <template v-else>
            <h2 class="catalog__name">Избранное</h2>
          </template>
          <div class="catalog__search search-favourites" v-show="favouritesOpened">
            <span class="catalog__icon"><img src="@/assets/img/Search.png" alt=""></span>
            <input
              @input="searchFavourites"
              :value="favouritesSearch"
              class="catalog__search-form"
              type="search" placeholder="Поиск..."
            />
          </div>
        </div>
      </div>
    </div>
    <div class="catalog__catalog">
      <div class="catalog__oll" v-show="catalogOpened">
        <div class="catalog__list">
          <catalog-letters
            type="catalog"
            :letters="catalogLetters"
            :current="catalogLetter"
            @letter-selected="selectLetter($event, 'catalog')"
            @clear-letter="selectLetter(null, 'catalog')"
          ></catalog-letters>
          <infinite-scroll
            :page-size="100"
            class="catalog__box custom-scroll"
            payload-url="/catalog"
            ref="catalogScroll"
            :query-params="catalogSearchQuery"
            type="catalog"
            key="catalogScroll"
            items-index="songs"
            @data-loaded="setCatalogRaw"
            v-slot="{item, index}">
            <div class="catalog__lists">
              <div class="catalog__text">
                <h2>{{item.code}}</h2>
                <p>{{getType(item)}}</p>
              </div>
              <div class="catalog__song">
                <h2>{{item.name}}</h2>
                <p>{{item.singer}}</p>
              </div>
              <div class="catalog__star">
                <div class="catalog__lable">
                  <a href="#" id="star2" class="catalog__lable" @click.prevent="toFavourites(item, index)">
                    <img src="@/assets/img/Star.png" v-if="item.in_favourites" alt="">
                    <img src="@/assets/img/Star2.png" v-else alt="">
                  </a>
                </div>
              </div>
            </div>
          </infinite-scroll>
        </div>
      </div>
      <div class="catalog__favourites" v-show="favouritesOpened">
        <div class="catalog__list">
          <catalog-letters
            type="favourites"
            :letters="favouritesLetters"
            :current="favouritesLetter"
            @letter-selected="selectLetter($event, 'favourites')"
            @clear-letter="selectLetter(null, 'favourites')"
          ></catalog-letters>
          <infinite-scroll
            :page-size="100"
            class="catalog__box custom-scroll"
            payload-url="/catalog/favourites"
            :query-params="favouritesSearchQuery"
            type="favourites"
            ref="favouritesScroll"
            key="favouritesScroll"
            items-index="songs"
            @data-loaded="setFavouritesRaw"
            v-slot="{item}">
            <div class="catalog__lists">
              <div class="catalog__text">
                <h2>{{item.code}}</h2>
                <p>{{getType(item)}}</p>
              </div>
              <div class="catalog__song">
                <h2>{{item.name}}</h2>
                <p>{{item.singer}}</p>
              </div>
              <div class="catalog__star">
                <div class="catalog__lable">
                  <div class="temp-tone" v-if="item.temp || item.tone">
                    <div v-if="item.temp">Темп: {{item.temp}}</div>
                      <div v-if="item.tone">Тональность: {{item.tone}}</div>
                    </div>
                  <a href="#" id="star2" @click.prevent="fromFavourites(item)">
                    <img src="@/assets/img/Star.png" alt="">
                  </a>
                </div>
              </div>
            </div>
          </infinite-scroll>
        </div>
      </div>
    </div>
    <catalog-favourite-modal ref="favouriteModal" @update-item="updateItem"></catalog-favourite-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import CatalogFavouriteModal from '@/views/CatalogFavouriteModal.vue';
import CatalogLetters from '@/views/CatalogLetters.vue';

export default {
  components: {
    InfiniteScroll,
    CatalogFavouriteModal,
    CatalogLetters,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      currentTab: 'all',
      catalogSearchDebounce: null,
      favouritesSearchDebounce: null,
      catalogRaw: null,
      favouritesRaw: null,
      catalogLetter: null,
      favouritesLetter: null,
    };
  },
  created() {
    this.catalogSearchDebounce = _.debounce(this.searchCatalogSend, 500);
    this.favouritesSearchDebounce = _.debounce(this.searchFavouritesSend, 500);
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  computed: {
    catalogLetters() {
      if (!this.catalogRaw) {
        return [];
      }
      return this.catalogRaw.first_letters || [];
    },
    favouritesLetters() {
      if (!this.favouritesRaw) {
        return [];
      }
      return this.favouritesRaw.first_letters || [];
    },
    catalogSearchQuery() {
      return {
        search: this.catalogSearch,
        firstLetter: this.catalogLetter,
      };
    },
    favouritesSearchQuery() {
      return {
        search: this.favouritesSearch,
        firstLetter: this.favouritesLetter,
      };
    },
    catalogSearch: {
      cache: false,
      get() {
        return this.$store.getters.catalogSearch;
      },
      set(value) {
        this.$store.commit('setCatalogSearch', value);
      },
    },
    favouritesSearch: {
      cache: false,
      get() {
        return this.$store.getters.favouritesSearch;
      },
      set(value) {
        this.$store.commit('setFavouritesSearch', value);
      },
    },
    catalogOpened() {
      if (!this.isMobile) {
        return true;
      }
      return this.currentTab === 'all';
    },
    favouritesOpened() {
      if (!this.isMobile) {
        return true;
      }
      return this.currentTab === 'favourites';
    },
    isMobile() {
      return this.innerWidth <= 1200;
    },
  },
  methods: {
    selectLetter(letter, type) {
      this.$set(this, `${type}Letter`, letter);
      this.$nextTick(() => {
        this.$refs[`${type}Scroll`].getData(true);
      });
    },
    setCatalogRaw(data) {
      this.$set(this, 'catalogRaw', data);
    },
    setFavouritesRaw(data) {
      this.$set(this, 'favouritesRaw', data);
    },
    selectTab(tab) {
      this.currentTab = tab;
    },
    resizeHandler(event) {
      this.innerWidth = event.target.innerWidth;
    },
    updateItem({
      item,
      index,
      tone,
      temp,
    }) {
      const favouriteItem = JSON.parse(JSON.stringify(item));
      favouriteItem.tone = tone;
      favouriteItem.temp = temp;
      this.$refs.favouritesScroll.addItem(favouriteItem);
      const newItem = JSON.parse(JSON.stringify(item));
      newItem.in_favourites = true;
      this.$refs.catalogScroll.updateItemByIndex(index, newItem);
    },
    toFavourites(item, index) {
      if (item.in_favourites) {
        this.fromFavourites(item);
        const newItem = JSON.parse(JSON.stringify(item));
        newItem.in_favourites = false;
        this.$refs.catalogScroll.updateByKey('id', item.id, newItem);
      } else {
        this.$refs.favouriteModal.open(item, index);
      }
    },
    fromFavourites(item) {
      this.$http.delete(`/catalog/favourite/${item.id}`).then((response) => {
        if (response.data) {
          this.$refs.favouritesScroll.deleteItemByKey('id', item.id);
          const newItem = JSON.parse(JSON.stringify(item));
          newItem.in_favourites = false;
          this.$refs.catalogScroll.updateByKey('id', item.id, newItem);
        }
      });
    },
    getType(item) {
      const arr = [];
      if (item.type) {
        arr.push(item.type);
      }
      if (item.has_back) {
        arr.push('БЭК');
      }
      return arr.join(' ');
    },
    searchCatalogSend() {
      this.$refs.catalogScroll.getData(true);
    },
    searchCatalog(event) {
      this.catalogSearch = event.target.value;
      if (!event.target.value.length) {
        this.$refs.catalogScroll.clearSearch();
      }
      this.catalogSearchDebounce(event);
    },
    searchFavouritesSend() {
      this.$refs.favouritesScroll.getData(true);
    },
    searchFavourites(event) {
      this.favouritesSearch = event.target.value;
      if (!event.target.value.length) {
        this.$refs.favouritesScroll.clearSearch();
      }
      this.favouritesSearchDebounce(event);
    },
  },
};
</script>
